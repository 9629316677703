import React from 'react'
import styled from 'styled-components'
import { between, hsl } from 'polished'
import { ValidatorComponent } from 'react-form-validator-core'
import Icon from '../images/icons/checkmark.svg'

const Checkmark = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  margin: 2px 5px 5px 0;
  border: 1px solid black;
  background-color: #fff;
  transition: all 200ms cubic-bezier(0.2, 0.54, 0.58, 0.7);

  &:after {
    display: none;
    width: 19px;
    height: 19px;
    background-image: url('${Icon}');
    background-repeat: no-repeat;
    content: '';
  }

  &:hover {
    border-color: grey!important;
  }

  &:focus {
    border-color: ${hsl(198, 1, 0.45)};!important;
  }
`

const Label = styled.label`
  position: relative;
  display: inline-block;
  text-align: left;
  width: calc(100% - 30px);
  margin: 20px 15px 30px 15px;
  padding-left: 35px;
  font-size: ${between('14px', '18px', '320px', '2560px')};
  font-weight: 100;
  line-height: 25px;
  cursor: pointer;
  user-select: none;
  color: #000;

  &:focus,
  span:focus {
    color: ${hsl(198, 1, 0.45)};
  }
  @media (max-width: 768px) {
    margin: 10px 0;
    width: 100%;
  }
  input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
    cursor: pointer;

    &:checked ~ ${Checkmark} {
      border-color: black;
      &:after {
        display: block;
      }
    }
  }
`
class CheckboxValidator extends ValidatorComponent {
  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      ...rest
    } = this.props

    return (
      <div>
        <input
          {...rest}
          ref={r => {
            this.input = r
          }}
        />
        <Checkmark />
        {this.errorText()}
      </div>
    )
  }

  errorText() {
    const { isValid } = this.state

    if (isValid) {
      return null
    }

    return (
      <div
        style={{
          color: 'red',
          fontSize: '12px',
          display: 'inline-block',
          position: 'absolute',
          left: 0,
          bottom: '-24px',
        }}
      >
        {this.getErrorMessage()}
      </div>
    )
  }
}

const Checkbox = ({ label, name, onChange, checked, ...rest }) => (
  <Label {...rest}>
    {label}
    <CheckboxValidator
      type="checkbox"
      name="checkbox"
      onChange={onChange}
      value={checked}
      validators={['required']}
      errorMessages={['Sie müssen den AGB zustimmen']}
    />
  </Label>
)

export default styled(Checkbox)``
