import React, { useState } from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { hsl } from 'polished'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { ValidatorForm, ValidatorComponent } from 'react-form-validator-core'

import Layout from '../components/Layout'
import { ProductGrid, Product } from '../components/Product'
import Section from '../components/Section'
import { Input, FormContent } from '../components/Form'
import Select from '../components/Select'
import Checkbox from '../components/Checkbox'
import Button from '../components/Button'
import Modal from '../components/Modal'
import BlackSpinner from '../components/BlackSpinner'

import checkmark from '../images/icons/checkmark.svg'

class TextValidator extends ValidatorComponent {
  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      ...rest
    } = this.props

    return (
      <div style={{ display: 'unset', position: 'relative' }}>
        <Input
          {...rest}
          ref={r => {
            this.input = r
          }}
        />
        {this.errorText()}
      </div>
    )
  }

  errorText() {
    const { isValid } = this.state

    if (isValid) {
      return null
    }

    return (
      <div
        style={{
          color: 'red',
          fontSize: '12px',
          margin: '0 0 0 15px',
          display: 'inline-block',
          position: 'absolute',
          left: 0,
          bottom: '-18px',
        }}
      >
        {this.getErrorMessage()}
      </div>
    )
  }
}

class SelectValidator extends ValidatorComponent {
  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      ...rest
    } = this.props

    return (
      <div style={{ position: 'relative' }}>
        <Select
          {...rest}
          ref={r => {
            this.input = r
          }}
        />
        {this.errorText()}
      </div>
    )
  }

  errorText() {
    const { isValid } = this.state

    if (isValid) {
      return null
    }

    return (
      <div
        style={{
          color: 'red',
          fontSize: '12px',
          margin: '0 0 0 15px',
          display: 'inline-block',
          position: 'absolute',
          left: 0,
          bottom: '-18px',
        }}
      >
        {this.getErrorMessage()}
      </div>
    )
  }
}

const THANKS_FORM_MUTATION = gql`
  mutation ThanksForm($data: ThanksFormDataInput!) {
    thanksForm(data: $data)
  }
`

const ModalWrapper = styled.div`
  display: flex;
`
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 945px) {
    flex-direction: column;
  }

  > button {
    margin: 0 10px;

    @media (max-width: 945px) {
      margin: 10px;
    }

    span {
      &:before {
        display: none;
      }
    }

    &.negate {
      background-color: #fff;
      border: 1px solid ${hsl(198, 1, 0.45)};
      color: ${hsl(198, 1, 0.45)};

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
`

const Danke = React.memo(props => {
  const windowGlobal = typeof window !== 'undefined' && window

  const [offer] = useState(
    windowGlobal
      ? new URLSearchParams(windowGlobal.location.search).get('offer')
      : null
  )
  const [isPrintSubscriber, setIsPrintSubscriber] = useState(null)

  const [salutation, setSalutation] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [street, setStreet] = useState('')
  const [houseNumber, setHouseNumber] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [city, setCity] = useState('')
  const [email, setEmail] = useState('')
  const [emailConfirm, setEmailConfirm] = useState('')
  const [confirm, setConfirm] = useState(null)

  const [submittingForm, setSubmittingForm] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formSubmitError, setFormSubmitError] = useState(false)

  const submitForm = e => {
    e.preventDefault()

    setSubmittingForm(true)
    setFormSubmitError(false)

    const variables = {
      data: {
        offer,
        isPrintSubscriber,
        salutation,
        firstName,
        lastName,
        street,
        houseNumber,
        zipCode,
        city,
        email,
      },
    }

    props.client
      .mutate({
        mutation: THANKS_FORM_MUTATION,
        variables,
      })
      .then(res => {
        setSubmittingForm(false)
        setFormSubmitted(true)
      })
      .catch(err => {
        setSubmittingForm(false)
        setFormSubmitError(true)
      })
  }

  return (
    <Layout withoutMenu>
      <Helmet>
        <title>Danke</title>
      </Helmet>

      <Modal
        isOpen={
          offer === 'afccb33b-dbc8-40c2-86e3-1f5483412a3a' &&
          isPrintSubscriber === null
        }
        showClose={false}
      >
        <ModalWrapper>
          <ModalContent>
            <p>Haben Sie ihre Tageszeitung bereits abonniert?</p>

            <ButtonWrapper>
              <Button onClick={() => setIsPrintSubscriber(true)}>
                Ja, ich bin Abonnent
              </Button>

              <Button
                className="negate"
                onClick={() => setIsPrintSubscriber(false)}
              >
                Nein
              </Button>
            </ButtonWrapper>
          </ModalContent>
        </ModalWrapper>
      </Modal>

      <Section variant="blue">
        <p style={{ margin: 0 }}>
          {offer === '29efee8f-840b-4321-a300-41581bc509a1' && (
            <>
              Jetzt <strong>1 Monat gratis</strong> testen
            </>
          )}

          {offer === 'afccb33b-dbc8-40c2-86e3-1f5483412a3a' && (
            <>
              Jetzt <strong>4 Monate lesen – 2 Monate</strong> testen
            </>
          )}
        </p>
      </Section>

      <ProductGrid>
        {offer === '29efee8f-840b-4321-a300-41581bc509a1' && (
          <Product
            highlightedOffer={true}
            title="1 Monat gratis testen"
            description="Testen Sie die digitale Zeitung unverbindlich für 1 Monat gratis! Dieses Angebot endet automatisch."
            image="https://zvw-shop.de/media/image/08/6c/c0/tablet-mit-hand-freigestellt592fb8576a887.jpg"
            features={
              <>
                <span>
                  <img src={checkmark} alt="" />
                  Unverbindlich
                </span>
                <span>
                  <img src={checkmark} alt="" />
                  Kostenlos
                </span>
                <span>
                  <img src={checkmark} alt="" />
                  Keine Kündigung notwendig
                </span>
              </>
            }
            withoutButton
            withoutAdditionalPayment
            thankYouOffer
          />
        )}

        {offer === 'afccb33b-dbc8-40c2-86e3-1f5483412a3a' && (
          <Product
            highlightedOffer={true}
            title="DANKE-SCHÖN-ANGEBOT"
            description="Lesen Sie jetzt unsere digitale Zeitung für 4 Monate, aber bezahlen Sie nur 2. Unser Danke-Schön-Angebot an Sie!"
            image="https://zvw-shop.de/media/image/08/6c/c0/tablet-mit-hand-freigestellt592fb8576a887.jpg"
            features={
              <>
                <span>
                  <img src={checkmark} alt="" />4 Monate lesen - 2 Monate
                  bezahlen
                </span>
                <span>
                  <img src={checkmark} alt="" />
                  Sie erhalten einen Dankeschön-Preis
                </span>
                <span>
                  <img src={checkmark} alt="" />
                  Keine Kündigung notwendig
                </span>
              </>
            }
            oldPrice={isPrintSubscriber ? 22 : 101.6}
            currentPrice={isPrintSubscriber ? 11 : 50.8}
            withoutButton
            withoutAdditionalPayment
            thankYouOffer
          />
        )}
      </ProductGrid>

      <Section
        variant="default"
        align="default"
        size="large"
        style={{ margin: '0 10px' }}
      >
        {!formSubmitted && (
          <>
            {offer === '29efee8f-840b-4321-a300-41581bc509a1' && (
              <>
                <p>
                  <strong>Vielen Dank,</strong> dass Sie unsere neue digitale
                  Zeitung testen.
                </p>
                <p>
                  Als <strong>Dankeschön schenken</strong> wir Ihnen{' '}
                  <strong>30 Tage</strong> lesen.
                </p>
                <p>
                  <br />
                </p>
              </>
            )}

            {offer === 'afccb33b-dbc8-40c2-86e3-1f5483412a3a' && (
              <>
                <p>
                  <strong>Vielen Dank,</strong> dass Sie unsere neue digitale
                  Zeitung getestet haben.
                </p>
                <p>
                  Unser <strong>Dankeschön-Angebot</strong> für Sie:{' '}
                  <strong>4 Monate lesen </strong> und nur{' '}
                  <strong>2 Monate bezahlen.</strong>
                </p>
                <p>
                  <br />
                </p>
              </>
            )}

            <ValidatorForm onSubmit={submitForm}>
              <FormContent>
                <SelectValidator
                  name="salutation"
                  onChange={e => setSalutation(e.target.value)}
                  value={salutation}
                  validators={['required']}
                  errorMessages={['Bitte Anrede angeben']}
                >
                  <option value="" disabled>
                    Anrede
                  </option>
                  <option value="Frau">Frau</option>
                  <option value="Herr">Herr</option>
                </SelectValidator>

                <TextValidator
                  name="firstName"
                  onChange={e => setFirstName(e.target.value)}
                  value={firstName}
                  variant="medium"
                  type="text"
                  placeholder="Vorname"
                  validators={['required']}
                  errorMessages={['Bitte Vorname angeben']}
                />

                <TextValidator
                  name="lastName"
                  onChange={e => setLastName(e.target.value)}
                  value={lastName}
                  variant="medium"
                  type="text"
                  placeholder="Nachname"
                  validators={['required']}
                  errorMessages={['Bitte Nachname angeben']}
                />

                <TextValidator
                  name="street"
                  onChange={e => setStreet(e.target.value)}
                  value={street}
                  variant="large"
                  type="text"
                  placeholder="Straße"
                  validators={['required']}
                  errorMessages={['Bitte Straße angeben']}
                />

                <TextValidator
                  name="houseNumber"
                  onChange={e => setHouseNumber(e.target.value)}
                  value={houseNumber}
                  variant="small"
                  type="text"
                  placeholder="Nr."
                  validators={['required']}
                  errorMessages={['Bitte Hausnummer angeben']}
                />

                <TextValidator
                  name="zipCode"
                  onChange={e => setZipCode(e.target.value)}
                  value={zipCode}
                  variant="small"
                  type="text"
                  placeholder="PLZ"
                  validators={['required']}
                  errorMessages={['Bitte PLZ angeben']}
                />

                <TextValidator
                  name="city"
                  onChange={e => setCity(e.target.value)}
                  value={city}
                  variant="large"
                  type="text"
                  placeholder="Ort"
                  validators={['required']}
                  errorMessages={['Bitte Ort angeben']}
                />

                <TextValidator
                  name="email"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                  variant="medium"
                  type="email"
                  placeholder="E-Mail-Adresse"
                  validators={['required', 'isEmail']}
                  errorMessages={[
                    'Bitte gültige E-Mail-Adresse angeben',
                    'Bitte gültige E-Mail-Adresse angeben',
                  ]}
                />

                <TextValidator
                  name="emailConfirm"
                  onChange={e => setEmailConfirm(e.target.value)}
                  value={emailConfirm}
                  variant="medium"
                  type="email"
                  placeholder="E-Mail-Adresse bestätigen"
                  validators={['required', 'isEmail']}
                  errorMessages={[
                    'Bitte gültige E-Mail-Adresse angeben',
                    'Bitte gültige E-Mail-Adresse angeben',
                  ]}
                />

                <Checkbox
                  label={
                    <>
                      Ich habe die Hinweise zum{' '}
                      <a
                        href="https://zvw-shop.de/datenschutz"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Datenschutz
                      </a>{' '}
                      und den{' '}
                      <a
                        href="https://zvw-shop.de/agb"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        AGB
                      </a>{' '}
                      gelesen und akzeptiere diese.
                    </>
                  }
                  onChange={e => setConfirm(e.target.checked ? 1 : null)}
                  checked={confirm}
                />
              </FormContent>

              <ButtonWrapper
                style={{ position: 'relative', alignItems: 'center' }}
              >
                <Button icon="none" type="submit">
                  {offer === '29efee8f-840b-4321-a300-41581bc509a1' && (
                    <>Kostenfrei bestellen</>
                  )}
                  {offer === 'afccb33b-dbc8-40c2-86e3-1f5483412a3a' && (
                    <>Zahlungspflichtig bestellen</>
                  )}
                </Button>

                {submittingForm && <BlackSpinner />}

                {formSubmitError && (
                  <p style={{ margin: 0 }}>
                    Leider hat es einen Fehler gegeben, bitte versuchen Sie es
                    in wenigen Augenblicken erneut.
                  </p>
                )}
              </ButtonWrapper>
            </ValidatorForm>
          </>
        )}

        {formSubmitted && (
          <>
            <p>
              <strong>Vielen Dank,</strong> wir führen ihre Bestellung umgehend
              aus.
            </p>
          </>
        )}
      </Section>
    </Layout>
  )
})

export default withApollo(Danke)
