import styled from 'styled-components'
import { between, hsl } from 'polished'

const Input = styled.input`
  padding: 15px 0 5px 0;
  font-size: ${between('14px', '18px', '320px', '2560px')};
  font-weight: 100;
  border: none;
  border-bottom: 2px solid #e6e6e6;
  transition: all 200ms linear;
  width: calc(100% - 30px);
  display: inline-block;
  transition: all 200ms linear;
  margin: 0 15px;
  margin-bottom: ${between('10px', '20px', '320px', '2560px')};

  &:hover {
    border-color: #9c9c9c;
  }

  &:focus {
    border-color: ${hsl(198, 1, 0.45)};
  }

  width: ${props =>
    props.variant === 'small'
      ? 'calc(33.3% - 30px)'
      : props.variant === 'medium'
      ? 'calc(50% - 30px)'
      : props.variant === 'large'
      ? 'calc(66.6% - 30px)'
      : 'calc(100% - 30px)'};
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`

const FormContent = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  @media (max-width: 768px) {
    margin: 0;
  }
`

export { FormContent, Input }
