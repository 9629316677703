import React from 'react'
import styled from 'styled-components'
import { between, hsl } from 'polished'

const Wrapper = styled.div`
  position: relative;
  margin: 0 15px;
  width: calc(33.3% - 30px);
  display: block;
  &:after {
    position: absolute;
    display: block;
    bottom: 16px;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #007bff transparent transparent transparent;
    pointer-events: none;
    content: '';
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`

const Dropdown = styled.select`
  margin: 0;
  position: relative;
  margin-bottom: ${between('10px', '20px', '320px', '2560px')};
  font-size: ${between('14px', '18px', '320px', '2560px')};
  display: block;
  width: 100%;
  padding: 10px 0;
  border: none;
  border-bottom: 2px solid #e6e6e6;
  border-radius: 0 !important;
  background-color: transparent;
  font-weight: 100;
  -webkit-appearance: none;
  transition: all 200ms linear;
  cursor: pointer;
  &:hover {
    border-color: grey;
  }

  &:focus {
    border-color: ${hsl(198, 1, 0.45)};
  }
`

const Select = ({ children, ...rest }) => (
  <Wrapper>
    <Dropdown {...rest}>{children}</Dropdown>
  </Wrapper>
)

export default Select
